/* Common Imports */

import { styled } from "@mui/system";
import React, { useRef } from "react";

/* Redux Imports */

import StateInterface from "../../redux-magic/state-interface";

/* Component Imports */

import {
	AppBar,
	FormControlLabel,
	Link,
	Menu,
	PopperPlacementType,
	Slide,
	Stack,
	Switch,
	Toolbar,
	Typography,
	useTheme,
} from "@mui/material";
import Popover from "@mui/material/Popover";
import BeegruButton from "../common-components/buttons/BeegruButton";
import ColorModeContext from "../layout-components/contexts/colorModeContext";
import CreateNewOFBWithUploadTool from "../modals/PropertyModal/CreateNewOFBWithUploadTool";
import CreatePropertyWithUploadTool from "../modals/PropertyModal/CreatePropertyWithUploadTool";
import CreateServiceWithUploadTool from "../modals/ServiceModals/CreateServiceWithUploadTool";

import Notifications from "./components/Notifications";
import UserMenu from "./components/UserMenu";

/* Icon Imports */

import { AddBusinessOutlined, AddHomeWorkOutlined, AddOutlined, HomeRepairServiceOutlined } from "@mui/icons-material";
import dynamic from "next/dynamic";

/* Authentication Imports */

import { signOut } from "next-auth/react";
const RecoveryEmailSnackbar = dynamic(() =>
	import("../page-sections/Index/RecoveryEmailSnackbar").then((mod) => mod.default),
);
/* Routing Imports */

import { useRouter } from "next/router";

import BeegruLogoDark from "../../public/images/logo/logo-white.svg";
import BeegruLogoLight from "../../public/images/logo/logo.svg";
import {
	setCreateNewPropertyCurrentPageThunk,
	setCreateNewPropertyTransactionTypeThunk,
	setCreateNewServiceCurrentPageThunk,
} from "../../redux-magic/thunks";

/* Styled Component */

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
	width: 62,
	height: 34,
	padding: 7,
	"& .MuiSwitch-switchBase": {
		margin: 1,
		padding: 0,
		transform: "translateX(6px)",
		"&.Mui-checked": {
			color: "#FFFFFF",
			transform: "translateX(22px)",
			"& .MuiSwitch-thumb:before": {
				backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
					"#fff",
				)}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
			},
			"& + .MuiSwitch-track": {
				opacity: 1,
				backgroundColor: theme.palette.secondary.dark,
			},
		},
	},
	"& .MuiSwitch-thumb": {
		backgroundColor: theme.palette.primary.main,
		width: 32,
		height: 32,
		borderRadius: "8px",
		"&:before": {
			content: "''",
			position: "absolute",
			width: "100%",
			height: "100%",
			left: 0,
			top: 0,
			backgroundRepeat: "no-repeat",
			backgroundPosition: "center",
			backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
				"#fff",
			)}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
		},
	},
	"& .MuiSwitch-track": {
		opacity: 1,
		backgroundColor: theme.palette.mode === "dark" ? "#333" : "#AAB4BE",
		borderRadius: "8px",
	},
}));

const SectionContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",

	// padding: "0.5rem 0rem 0.5rem 0rem",
	padding: "0.5rem 4rem 0.5rem 4rem",
	// height: "4rem",
	[theme.breakpoints.down("sm")]: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "space-between",
		// margin: "0rem 0.4rem 0rem 0.4rem",
		padding: "0.5rem 0.5rem 0.5rem 0.5rem",
	},
	[theme.breakpoints.only("smPlus")]: {
		// padding: "0.5rem 3rem 0.5rem 3rem",
	},
}));
const WhiteContainer = styled("div")(({ theme }) => ({
	display: "flex",
	justifyContent: "center",
	alignContent: "center",
	padding: "0.75rem 0.875rem 0.75rem 0.875rem",
	background: theme.palette.mode === "dark" ? "#424242" : "#FFFFFF",
	borderRadius: "16px",
	[theme.breakpoints.down("sm")]: {
		padding: "0.35rem 0.475rem 0.35rem 0.475rem",
	},
}));

const RowContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
}));

const SocialRow = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	// margin: "0rem 1rem 0rem 0rem",
	gap: "1rem",
	[theme.breakpoints.down("xs")]: {
		display: "flex",
		margin: "0.5rem 0rem 0.5rem 0rem",
	},
}));

const ButtonContain = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
}));

const ContentContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "flex-start",
	width: "100%",
	overflow: "hidden",
	padding: "0.75rem 0.25rem 0rem 0.25rem",
	gap: "0.75rem",
}));

const ContentRow = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	width: "100%",
	padding: "0rem 0.5rem 0rem 0.25rem",
	gap: "0.25rem",
	cursor: "pointer",
	"&:hover": {
		color: theme.palette.primary.main,
	},
}));

const CustomLink = styled(Link)(({ theme }) => ({
	textDecoration: "none",
	fontSize: "1rem",
	fontWeight: 500,
	padding: "0rem 0rem 0rem 0.5rem",
	color: theme.palette.mode === "dark" ? "#FFFFFF" : "#000",
	"&:hover": {
		color: theme.palette.primary.main,
		cursor: "pointer",
	},
}));

const NameTypeContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "flex-start",
	width: "100%",
}));

const Heading = styled(Typography)(({ theme }) => ({
	fontSize: "0.875rem",
	fontWeight: 400,
}));

const TypeTypo = styled(Typography)(({ theme }) => ({
	fontWeight: "400",
	fontSize: "0.75rem",
	color: theme.palette.mode == "dark" ? "#C6C6C6" : "#666666",
}));

const BeegruLogo = ({ mode }: { mode: string }) => (
	<Link
		href="/"
		rel="noopener"
		target="_self"
		referrerPolicy="no-referrer"
		aria-label="Marketplace"
		title="Home"
	>
		{mode === "dark" ? (
			<BeegruLogoDark
				width={102}
				height={30}
				viewBox="0 0 102 30"
			/>
		) : (
			<BeegruLogoLight
				width={102}
				height={30}
				viewBox="0 0 102 30"
			/>
		)}
	</Link>
);

/* Default Export */

const LayoutHeader = ({
	session,
	redux_session_updated,
	dispatch,
	profile_context,
	new_business_profile_url,
	new_listing_url,
	notification_data,
	new_notification,
	newPropertyData,
	studio,
	newServiceData,
}: {
	session?: any;
	redux_session_updated?: boolean;
	dispatch: Function;
	profile_context: StateInterface["profile_context"];
	new_business_profile_url: StateInterface["new_business_profile_url"];
	new_listing_url: StateInterface["new_listing_url"];
	notification_data: StateInterface["notifications_page"];
	new_notification: StateInterface["notification"] | null;
	newPropertyData: StateInterface["new_property_data"];
	studio: StateInterface["is_studio"];
	newServiceData: StateInterface["new_service_data"];
}) => {
	const theme = useTheme();
	const router = useRouter();

	const signIn = () => {
		window.location.href = "/auth/login?callbackUrl=" + encodeURIComponent(window.location.href);
	};

	const [scrollY, setScrollY] = React.useState<number>(0);
	const [negativeScroll, setNegativeScroll] = React.useState<boolean>(true);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [isiOS, setIsiOS] = React.useState(false);

	const handleClick = (event: any) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;
	/* Effect Definitions */
	const [scroll, setScroll] = React.useState(0);
	React.useEffect(() => {
		/* First let's define an event listener */

		const handleScroll = () => {
			setScroll(window.scrollY);
			if (window.scrollY < scrollY) {
				setNegativeScroll(true);
			} else {
				setNegativeScroll(false);
			}
			setScrollY(window.scrollY);
			setScroll(window.scrollY);
		};
		/* Now we can add the event listener */

		window.addEventListener("scroll", handleScroll, { passive: true });
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, [scrollY]);

	React.useEffect(() => {
		function iOS() {
			return (
				["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(
					navigator.platform,
				) ||
				// iPad on iOS 13 detection
				(navigator.userAgent.includes("Mac") && "ontouchend" in document)
			);
		}
		if (iOS()) {
			setIsiOS(true);
		} else {
			setIsiOS(false);
		}
	}, []);

	/*

    * Property modal handlelick

  */

	const [create, setCreate] = React.useState(false);
	const [CreateNewOfbPropertyModals, setCreateNewOfbPropertyModals] = React.useState(false);

	const handleCreateOpen = () => {
		dispatch(setCreateNewPropertyCurrentPageThunk("navbar"));
		setCreate(true);
		setOpenPopper(false);
	};

	const handleCreateClose = () => {
		setCreate(false);
	};

	const handleCreateNewOfbPropertyOpen = async () => {
		dispatch(setCreateNewPropertyTransactionTypeThunk("ofb"));
		dispatch(setCreateNewPropertyCurrentPageThunk("navbar"));
		setCreateNewOfbPropertyModals(true);
		setOpenPopper(false);
	};
	const handleCreateNewOfbPropertyClose = async () => {
		setCreateNewOfbPropertyModals(false);
	};

	/*

    * Service modal handlelick

  */

	const [openservice, setOpenService] = React.useState(false);

	const handleOpenNewService = () => {
		dispatch(setCreateNewServiceCurrentPageThunk("navbar"));
		session === null ? window.open("/auth/login", "_self") : setOpenService(true);
		setOpenPopper(false);
	};

	const handleCloseNewService = () => {
		setOpenService(false);
	};

	/* popper for c*/

	const [anchorElPopper, setAnchorElPopper] = React.useState<HTMLButtonElement | null>(null);
	const [openPopper, setOpenPopper] = React.useState(false);
	const [placementPopper, setPlacementPopper] = React.useState<PopperPlacementType>();

	const handleClickPopper = (newPlacement: PopperPlacementType) => (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorElPopper(event.currentTarget);
		setOpenPopper((prev) => placementPopper !== newPlacement || !prev);
		setPlacementPopper(newPlacement);
	};

	/*

    * fix for popper. Close, if clicked anywhere else other than the popper

  */

	const useOutsideAlerter = (ref: any) => {
		React.useEffect(() => {
			/*

        * if clicked on outside the popper element

      */
			function onScroll(event: any) {
				if (ref.current && !ref.current.contains(event.target)) {
					setOpenPopper(false);
				}
			}
			function handleClickOutside(event: any) {
				if (ref.current && !ref.current.contains(event.target)) {
					setOpenPopper(false);
				}
			}
			// Binding event listener for clickoutside
			document.addEventListener("mousedown", handleClickOutside);
			// Binding event listener for scroll
			document.addEventListener("scroll", onScroll);
			return () => {
				// Unbind the event listener on clean up
				document.removeEventListener("mousedown", handleClickOutside);
				document.addEventListener("scroll", onScroll);
			};
		}, [ref]);
	};

	const wrapperRef = useRef(null);

	useOutsideAlerter(wrapperRef);
	const pathname = React.useRef(router).current;

	React.useEffect(() => {
		if (pathname.query.create_property === "true" && redux_session_updated === true) {
			redux_session_updated === true && session && session.authorized ? setCreate(true) : signIn();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [redux_session_updated]);
	React.useEffect(() => {
		if (pathname.query.create_property === "true" && redux_session_updated === true && create === false) {
			router.push("");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [create]);
	/*

    * Slug state to redirect to particular page

  */

	// const Slug: any = session?.user?.dbUser?.pages
	// 	? session.user.dbUser.pages.find((item: any) => item.page_id === profile_context?.business_profile_id)?.page_slug
	// 	: null;

	// const [userUrl, setUserUrl] = React.useState<string>(
	//   session === null
	//     ? "/auth/login"
	//     : profile_context?.page_type === "user"
	//       ? `/users/${
	//           session?.user?.dbUser?._id ? session?.user?.dbUser?._id : ""
	//         }`
	//       : `/${profile_context?.page_type}s/${Slug}`
	// );

	const [loginDisabled, setLoginDisabled]: [boolean, Function] = React.useState<boolean>(false);

	// React.useEffect(() => {
	// 	const csrfWait = setTimeout(() => {
	// 		setLoginDisabled(false);
	// 	}, 3000);
	// 	return () => clearTimeout(csrfWait);
	// }, []);

	const [isReady, setIsReady] = React.useState(false);
	React.useEffect(() => {
		// Check if the conditions are met
		if (redux_session_updated !== undefined && redux_session_updated === true) {
			setIsReady(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [redux_session_updated]);

	const CustomNavChanges = () => {
		/*

		! if else condition or switch or ternary operators, will have to write the same code in the else condition. in this, kinda like destructuring and saving it in variables and returning the variables with or without the whitecontainer. this way redundant code will be excluded.

		*/

		const RenderSocialRow = () => (
			<SocialRow>
				{/* Post */}

				{redux_session_updated && redux_session_updated === true && (
					<BeegruButton
						flavor="success"
						variant={session ? "contained" : "outlined"}
						size={"medium"}
						sx={{
							"& .MuiButton-endIcon": {
								margin: "0rem 0rem 0rem 0.25rem",
							},
							[theme.breakpoints.down("sm")]: {
								display: "none",
							},
						}}
						startIcon={<AddOutlined />}
						onClick={handleClickPopper("bottom")}
					>
						Post
					</BeegruButton>
				)}

				{/* Notifications */}

				{redux_session_updated && redux_session_updated === true && session && session.authorized && (
					<Notifications
						notification_data={notification_data}
						profile_context={profile_context}
						session={session}
						dispatch={dispatch}
						new_notification={new_notification}
					/>
				)}

				{/* Dark Mode Switch */}

				{redux_session_updated && redux_session_updated === true && !session && (
					<ColorModeContext.Consumer>
						{({ toggleColorMode }) => (
							<FormControlLabel
								onChange={toggleColorMode}
								control={<MaterialUISwitch checked={theme.palette.mode === "dark"} />}
								label=""
								labelPlacement="start"
								sx={{ width: 39 }}
							/>
						)}
					</ColorModeContext.Consumer>
				)}

				{/* Login popover only for Galaxy Fold */}

				<ButtonContain>
					<Stack
						direction="row"
						sx={{
							[theme.breakpoints.up("xs")]: {
								display: "none",
							},
						}}
					>
						<Popover
							id={id}
							open={open}
							anchorEl={anchorEl}
							onClose={handleClose}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "right",
							}}
							transformOrigin={{
								vertical: "top",
								horizontal: "right",
							}}
						>
							<RowContainer>
								<Typography
									sx={{
										[theme.breakpoints.down("xs")]: {
											display: "flex",
											alignItems: "center",
										},
									}}
								>
									Theme :
								</Typography>
								<ColorModeContext.Consumer>
									{({ toggleColorMode }) => (
										<FormControlLabel
											onChange={toggleColorMode}
											control={<MaterialUISwitch checked={theme.palette.mode === "dark"} />}
											label=""
											labelPlacement="start"
											sx={{ width: 34 }}
										/>
									)}
								</ColorModeContext.Consumer>
							</RowContainer>
							<BeegruButton
								flavor="primary"
								variant="contained"
								size="small"
								disabled={loginDisabled}
								onClick={() => {
									session && session.authorized
										? signOut({
												callbackUrl:
													process.env.PRODUCTION_API_URL +
													"auth/end-session?sessionId=" +
													encodeURIComponent(session.session_id) +
													"&callbackUrl=" +
													encodeURIComponent(router.asPath),
											})
										: signIn();
								}}
								name="login"
								type="button"
								sx={{
									[theme.breakpoints.down("xs")]: {
										display: "flex",
										alignSelf: "center",
										margin: "0.5rem",
									},
								}}
							>
								{session && session.authorized ? "Logout" : "Login"}
							</BeegruButton>
						</Popover>
					</Stack>

					{redux_session_updated &&
						redux_session_updated === true &&
						(session ? (
							<UserMenu
								session={session}
								dispatch={dispatch}
								profile_context={profile_context}
								new_business_profile_url={new_business_profile_url}
								redux_session_updated={redux_session_updated}
							/>
						) : (
							<BeegruButton
								disabled={loginDisabled}
								flavor="primary"
								variant="contained"
								size="large"
								onClick={() => {
									session && session.authorized
										? signOut({
												callbackUrl:
													process.env.PRODUCTION_API_URL +
													"auth/end-session?sessionId=" +
													encodeURIComponent(session.session_id) +
													"&callbackUrl=" +
													encodeURIComponent(router.asPath),
											})
										: signIn();
								}}
								name="login"
								type="button"
								sx={{
									[theme.breakpoints.down("xs")]: {
										display: "none",
									},
								}}
							>
								{session && session.authorized ? "Logout" : "Login"}
							</BeegruButton>
						))}
				</ButtonContain>
			</SocialRow>
		);

		return (
			<React.Fragment>
				{(scroll === 0 && pathname.asPath === "/") || pathname.asPath === "/?create-property=true" ? (
					<React.Fragment>
						<WhiteContainer>
							<BeegruLogo mode={theme.palette.mode} />
						</WhiteContainer>
						{isReady && <WhiteContainer>{RenderSocialRow()}</WhiteContainer>}
					</React.Fragment>
				) : (
					<React.Fragment>
						<BeegruLogo mode={theme.palette.mode} />
						{RenderSocialRow()}
					</React.Fragment>
				)}
			</React.Fragment>
		);
	};

	return (
		<React.Fragment>
			{/* Property Modal */}

			<CreatePropertyWithUploadTool
				open={create}
				handleClose={handleCreateClose}
				dispatch={dispatch}
				new_listing_url={new_listing_url ? new_listing_url : ""}
				session={session}
				newPropertyData={newPropertyData}
				studio={studio}
			/>

			{/* Service Modal */}

			<CreateServiceWithUploadTool
				open={openservice}
				handleClose={handleCloseNewService}
				dispatch={dispatch}
				new_listing_url={new_listing_url ? new_listing_url : ""}
				session={session}
				newServiceData={newServiceData}
			/>

			{/* OFB Modal */}

			<CreateNewOFBWithUploadTool
				open={CreateNewOfbPropertyModals}
				handleClose={handleCreateNewOfbPropertyClose}
				creator_user_id={profile_context ? profile_context.user_id : ""}
				business_profile_id={profile_context ? profile_context.business_profile_id : ""}
				dispatch={dispatch}
				new_listing_url={new_listing_url ? new_listing_url : ""}
				profile_context={profile_context}
				session={session}
				newPropertyData={newPropertyData}
			/>

			{/* Post Menu */}

			<Menu
				open={openPopper}
				anchorEl={anchorElPopper}
				disableScrollLock={true}
				onClose={() => setOpenPopper(false)}
				slotProps={{
					paper: {
						sx: {
							borderRadius: "16px",
							margin: "0.5rem 0rem 0rem 0rem",
							backgroundColor: theme.palette.background.paper,
							backgroundImage: "none",
							gap: "0.5rem",
							padding: "0.75rem 0.5rem 0.75rem 0.5rem",
						},
					},
				}}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
			>
				<CustomLink
					href="/intro"
					rel="noopener"
					target="_blank"
					referrerPolicy="no-referrer"
				>
					Get into real estate
				</CustomLink>

				<ContentContainer>
					<ContentRow
						onClick={() => {
							session !== null ? handleCreateOpen() : signIn();
						}}
					>
						<AddHomeWorkOutlined
							color="primary"
							sx={{
								margin: "0rem 0.25rem 0rem 0rem",
								fontSize: "1.75rem",
							}}
						/>
						<NameTypeContainer>
							<Heading>Post property</Heading>
							<TypeTypo>Sell or rent out your property</TypeTypo>
						</NameTypeContainer>
					</ContentRow>

					<ContentRow
						onClick={() => {
							session !== null ? handleOpenNewService() : signIn();
						}}
					>
						<HomeRepairServiceOutlined
							color="primary"
							sx={{
								margin: "0rem 0.25rem 0rem 0rem",
								fontSize: "1.75rem",
							}}
						/>
						<NameTypeContainer>
							<Heading>Post service</Heading>
							<TypeTypo>Showcase your services</TypeTypo>
						</NameTypeContainer>
					</ContentRow>

					<ContentRow
						onClick={() => {
							session !== null ? handleCreateNewOfbPropertyOpen() : signIn();
						}}
					>
						<AddBusinessOutlined
							color="primary"
							sx={{
								margin: "0rem 0.25rem 0rem 0rem",
								fontSize: "1.75rem",
							}}
						/>
						<NameTypeContainer>
							<Heading>Post OFB</Heading>
							<TypeTypo>Utilize locations to your benefit</TypeTypo>
						</NameTypeContainer>
					</ContentRow>

					{/* <ContentRow
            onClick={() => {
              session !== null ? handleOpenBusinessProfile() : signIn();
            }}
          >
            <PersonAddAltOutlined
              color="primary"
              sx={{
                margin: "0rem 0.25rem 0rem 0rem",
                fontSize: "1.75rem",
              }}
            />
            <NameTypeContainer>
              <Heading>Create business profile</Heading>
              <TypeTypo>Post your lisitngs as a business</TypeTypo>
            </NameTypeContainer>
          </ContentRow> */}
				</ContentContainer>
			</Menu>

			<Slide
				appear={false}
				direction="down"
				in={isiOS || negativeScroll}
			>
				<AppBar
					position="fixed"
					color="transparent"
					elevation={0}
				>
					<SectionContainer
						// sx={{ backdropFilter: scrollY !== 0 ? "blur(1rem)" : "" }}
						sx={{
							alignItems:
								(scroll === 0 && pathname.asPath === "/") || pathname.asPath === "/?create-property=true"
									? "flex-start"
									: "center",
							backgroundColor:
								scroll === 0 && (pathname.asPath === "/" || pathname.asPath === "/?create-property=true")
									? "transparent"
									: theme.palette.mode === "dark"
										? "#424242"
										: "#ffffff",
							height: scroll === 0 && pathname.asPath === "/" ? "100%" : "4rem",
						}}
					>
						{CustomNavChanges()}
					</SectionContainer>
				</AppBar>
			</Slide>
			<Toolbar sx={{ "&.MuiToolbar-root": { minHeight: "0px" } }} />

			{/* {session &&
			session.user &&
			session.user.dbUser &&
			(session.user.dbUser.recovery_email.email === "" || session.user.dbUser.recovery_email.is_verified === false) ? (
				<RecoveryEmailSnackbar session={session} />
			) : null} */}
		</React.Fragment>
	);
};

export default LayoutHeader;
{
	/* {session !== null ? (
                  <UserMenu
                    session={session}
                    dispatch={dispatch}
                    profile_context={profile_context}
                    new_business_profile_url={new_business_profile_url}
                  />
                ) : (
                  <BeegruButton
                    disabled={loginDisabled}
                    flavor="primary"
                    variant="contained"
                    size="large"
                    onClick={() => {
                      session && session.authorized
                        ? signOut({
                            callbackUrl:
                              process.env.PRODUCTION_API_URL +
                              "auth/end-session?sessionId=" +
                              encodeURIComponent(session.session_id) +
                              "&callbackUrl=" +
                              encodeURIComponent(router.asPath),
                          })
                        : signIn();
                    }}
                    name="login"
                    type="button"
                    sx={{
                      [theme.breakpoints.down("xs")]: {
                        display: "none",
                      },
                    }}
                  >
                    {session && session.authorized
                      ? "Logout"
                      : "Login"}
                  </BeegruButton>
                )} */
}
